import {FaRegCalendarAlt, FaRegHospital} from 'react-icons/fa';
import {FiMail, FiMap, FiUsers} from 'react-icons/fi';
import {HiOutlineAcademicCap, HiOutlineChartSquareBar} from 'react-icons/hi';
import {
  RiBarChart2Line,
  RiCustomerService2Line,
  RiDashboardLine,
  RiShieldUserLine,
  RiTodoLine,
} from 'react-icons/ri';
import {BiCartAlt, BiDollar, BiErrorAlt, BiBookReader} from 'react-icons/bi';
import {CgComponents} from 'react-icons/cg';
import {
  BsBriefcase,
  BsCart4,
  BsChatDots,
  BsCurrencyBitcoin,
  BsQuestionDiamond,
} from 'react-icons/bs';
import {DiHtml5Multimedia} from 'react-icons/di';
import {
  MdInvertColors,
  MdOutlineAnalytics,
  MdOutlineContactPhone,
  MdOutlineContactSupport,
  MdOutlineDns,
  MdOutlineManageAccounts,
  MdTimeline,
} from 'react-icons/md';
import {CgAttachment, CgFeed} from 'react-icons/cg';
import {AiOutlineUnorderedList} from 'react-icons/ai';
import React from 'react';

const routesConfig = [
  {
    id: 'app',
    title: 'Application',
    messageId: 'sidebar.application',
    type: 'group',
    children: [
      {
        id: 'crypto',
        title: 'Crypto',
        messageId: 'sidebar.app.dashboard.crypto',
        type: 'item',
        icon: <BsCurrencyBitcoin />,
        url: '/dashboards/crypto',
      },
      {
        id: 'crm',
        title: 'CRM',
        messageId: 'sidebar.app.dashboard.crm',
        type: 'item',
        icon: <RiCustomerService2Line />,
        url: '/dashboards/crm',
      },
      {
        id: 'analytics',
        title: 'Analytics',
        messageId: 'sidebar.app.dashboard.analytics',
        type: 'item',
        icon: <MdOutlineAnalytics />,
        url: '/dashboards/analytics',
      },
      {
        id: 'healthCare',
        title: 'Health Care',
        messageId: 'sidebar.healthCare',
        type: 'item',
        icon: <FaRegHospital />,
        url: '/dashboards/health-care',
      },
      {
        id: 'e-commerce',
        title: 'E-Commerce',
        messageId: 'sidebar.app.dashboard.eCommerce',
        type: 'item',
        icon: <BsCart4 />,
        url: '/dashboards/e-commerce',
      },
      {
        id: 'academy',
        title: 'Academy',
        messageId: 'sidebar.app.dashboard.academy',
        type: 'item',
        icon: <HiOutlineAcademicCap />,
        url: '/dashboards/academy',
      },
      {
        id: 'metrics',
        title: 'Metrics',
        messageId: 'sidebar.app.metrics',
        type: 'item',
        icon: <HiOutlineChartSquareBar />,
        url: '/dashboards/metrics',
      },
      {
        id: 'widgets',
        title: 'Widgets',
        messageId: 'sidebar.app.widgets',
        type: 'item',
        icon: <RiDashboardLine />,
        url: '/dashboards/widgets',
      },
    ],
  },
  {
    id: 'apps',
    title: 'Apps',
    messageId: 'sidebar.apps',
    type: 'group',
    children: [
      {
        id: 'mail',
        title: 'Mail',
        messageId: 'sidebar.apps.mail',
        type: 'item',
        count: 4,
        icon: <FiMail />,
        url: '/apps/mail',
      },
      {
        id: 'todo',
        title: 'ToDo',
        messageId: 'sidebar.apps.todo',
        type: 'item',
        count: 6,
        icon: <RiTodoLine />,
        color: '#48bb78',
        url: '/apps/todo',
      },
      {
        id: 'contact',
        title: 'Contact',
        messageId: 'sidebar.apps.contact',
        type: 'item',
        icon: <MdOutlineContactPhone />,
        url: '/apps/contact',
      },
      {
        id: 'chat',
        title: 'Chat',
        messageId: 'sidebar.apps.chat',
        type: 'item',
        icon: <BsChatDots />,
        url: '/apps/chat',
      },
      {
        id: 'scrum-board',
        title: 'Scrum Board',
        messageId: 'sidebar.apps.scrumboard',
        type: 'item',
        icon: <MdOutlineDns />,
        url: '/apps/scrum-board',
      },
      {
        id: 'wall',
        title: 'Wall',
        messageId: 'sidebar.apps.wall',
        type: 'item',
        icon: <CgFeed />,
        url: '/apps/wall',
      },
      {
        id: 'ecommerce',
        title: 'Ecommerce',
        messageId: 'sidebar.ecommerce',
        icon: <BiCartAlt />,
        type: 'collapse',
        children: [
          {
            id: 'products',
            title: 'Products',
            messageId: 'sidebar.ecommerce.products',
            type: 'item',
            url: '/ecommerce/products',
          },
          {
            id: 'product_detail',
            title: 'Product Detail',
            messageId: 'sidebar.ecommerce.productDetail',
            type: 'item',
            url: '/ecommerce/product_detail',
          },
          {
            id: 'orders',
            title: 'Orders',
            messageId: 'sidebar.ecommerce.orders',
            type: 'item',
            url: '/ecommerce/orders',
          },
          {
            id: 'customers',
            title: 'Customers',
            messageId: 'sidebar.ecommerce.customers',
            type: 'item',
            url: '/ecommerce/customers',
          },
          {
            id: 'cart',
            title: 'Cart',
            messageId: 'sidebar.ecommerce.cart',
            type: 'item',
            url: '/ecommerce/cart',
          },
          {
            id: 'checkout',
            title: 'Checkout',
            messageId: 'sidebar.ecommerce.checkout',
            type: 'item',
            url: '/ecommerce/checkout',
          },
          {
            id: 'confirmation',
            title: 'Confirmation',
            messageId: 'sidebar.ecommerce.confirmation',
            type: 'item',
            url: '/ecommerce/confirmation',
          },
          {
            id: 'invoice-1',
            title: 'Invoice 1',
            messageId: 'sidebar.ecommerce.invoice1',
            type: 'item',
            url: '/ecommerce/invoice-1',
          },
          {
            id: 'invoice-2',
            title: 'Invoice 2',
            messageId: 'sidebar.ecommerce.invoice2',
            type: 'item',
            url: '/ecommerce/invoice-2',
          },
        ],
      },
    ],
  },
  {
    id: 'bootstrap',
    title: 'Bootstrap Components',
    messageId: 'sidebar.bootComponents',
    type: 'group',
    children: [
      {
        id: 'components',
        title: 'Components',
        messageId: 'sidebar.components',
        icon: <CgComponents />,
        type: 'collapse',
        children: [
          {
            id: 'alert',
            title: 'Alert',
            messageId: 'sidebar.feedBack.alert',
            type: 'item',
            url: '/components/alert',
          },
          {
            id: 'accordion',
            title: 'Accordion',
            messageId: 'sidebar.accordion',
            type: 'item',
            url: '/components/accordion',
          },
          {
            id: 'badges',
            title: 'Badges',
            messageId: 'sidebar.badges',
            type: 'item',
            url: '/components/badges',
          },
          {
            id: 'bread-crumbs',
            title: 'Breadcrumbs',
            messageId: 'sidebar.breadcrumbs',
            type: 'item',
            url: '/components/bread-crumbs',
          },
          {
            id: 'buttons',
            title: 'Buttons',
            messageId: 'sidebar.buttons',
            type: 'item',
            url: '/components/buttons',
          },
          {
            id: 'button-groups',
            title: 'Button Groups',
            messageId: 'sidebar.buttongroups',
            type: 'item',
            url: '/components/button-groups',
          },
          {
            id: 'cards',
            title: 'Cards',
            messageId: 'sidebar.cards',
            type: 'item',
            url: '/components/cards',
          },
          {
            id: 'carousels',
            title: 'Carousels',
            messageId: 'sidebar.carousels',
            type: 'item',
            url: '/components/carousels',
          },
          {
            id: 'close-button',
            title: 'CloseButton',
            messageId: 'sidebar.closebutton',
            type: 'item',
            url: '/components/close-button',
          },
          {
            id: 'drop-downs',
            title: 'Dropdowns',
            messageId: 'sidebar.dropdowns',
            type: 'item',
            url: '/components/drop-downs',
          },
          {
            id: 'figures',
            title: 'Figures',
            messageId: 'sidebar.figures',
            type: 'item',
            url: '/components/figures',
          },
          {
            id: 'forms',
            title: 'Forms',
            messageId: 'sidebar.forms',
            type: 'item',
            url: '/components/forms',
          },
          {
            id: 'input-group',
            title: 'InputGroup',
            messageId: 'sidebar.inputgroup',
            type: 'item',
            url: '/components/input-group',
          },
          {
            id: 'images',
            title: 'Images',
            messageId: 'sidebar.images',
            type: 'item',
            url: '/components/images',
          },
          {
            id: 'list-groups',
            title: 'ListGroups',
            messageId: 'sidebar.listgroups',
            type: 'item',
            url: '/components/list-groups',
          },
          {
            id: 'modals',
            title: 'Modals',
            messageId: 'sidebar.modals',
            type: 'item',
            url: '/components/modals',
          },
          {
            id: 'navs',
            title: 'Navs',
            messageId: 'sidebar.navs',
            type: 'item',
            url: '/components/navs',
          },
          {
            id: 'navbars',
            title: 'Navbars',
            messageId: 'sidebar.navbars',
            type: 'item',
            url: '/components/navbars',
          },
          {
            id: 'offcanvas',
            title: 'Offcanvas',
            messageId: 'sidebar.offcanvas',
            type: 'item',
            url: '/components/offcanvas',
          },
          {
            id: 'overlays',
            title: 'Overlays',
            messageId: 'sidebar.overlays',
            type: 'item',
            url: '/components/overlays',
          },
          {
            id: 'pagination',
            title: 'Pagination',
            messageId: 'sidebar.pagination',
            type: 'item',
            url: '/components/pagination',
          },
          {
            id: 'place-holders',
            title: 'PlaceHolders',
            messageId: 'sidebar.placeholders',
            type: 'item',
            url: '/components/place-holders',
          },
          {
            id: 'progress',
            title: 'Progress',
            messageId: 'sidebar.progress',
            type: 'item',
            url: '/components/progress',
          },
          {
            id: 'spinners',
            title: 'Spinners',
            messageId: 'sidebar.spinners',
            type: 'item',
            url: '/components/spinners',
          },
          {
            id: 'tables',
            title: 'Tables',
            messageId: 'sidebar.tables',
            type: 'item',
            url: '/components/tables',
          },
          {
            id: 'tabs',
            title: 'Tables',
            messageId: 'sidebar.tabs',
            type: 'item',
            url: '/components/tabs',
          },
          {
            id: 'toasts',
            title: 'Toasts',
            messageId: 'sidebar.toasts',
            type: 'item',
            url: '/components/toasts',
          },
        ],
      },
    ]
  },
  {
    id: 'third-party',
    title: 'Libs',
    messageId: 'sidebar.libs',
    type: 'group',
    children: [
      {
        id: 'google-map',
        title: 'Google Map',
        messageId: 'sidebar.googleMap',
        icon: <FiMap />,
        url: 'google-map',
        type: 'collapse',
        children: [
          {
            id: 'simple',
            title: 'Simple',
            messageId: 'sidebar.googleMap.simple',
            type: 'item',
            url: '/third-party/google-map/simple',
          },
          {
            id: 'directions',
            title: 'Directions',
            messageId: 'sidebar.googleMap.directions',
            type: 'item',
            url: '/third-party/google-map/directions',
          },
          {
            id: 'drawing-view',
            title: 'DrawingView',
            messageId: 'sidebar.googleMap.drawingView',
            type: 'item',
            url: '/third-party/google-map/drawing-view',
          },
          {
            id: 'event-handler',
            title: 'Event Handler',
            messageId: 'sidebar.googleMap.eventHandler',
            type: 'item',
            url: '/third-party/google-map/event-handler',
          },
          {
            id: 'geolocation',
            title: 'GeoLocation',
            messageId: 'sidebar.googleMap.geoLocation',
            type: 'item',
            url: '/third-party/google-map/geolocation',
          },
          {
            id: 'km-layer',
            title: 'KmLayer',
            messageId: 'sidebar.googleMap.kmLayer',
            type: 'item',
            url: '/third-party/google-map/km-layer',
          },
          {
            id: 'map-clustering',
            title: 'MapClustering',
            messageId: 'sidebar.googleMap.mapClustering',
            type: 'item',
            url: '/third-party/google-map/map-clustering',
          },
          {
            id: 'map-overlay',
            title: 'MapOverlay',
            messageId: 'sidebar.googleMap.mapOverlay',
            type: 'item',
            url: '/third-party/google-map/map-overlay',
          },
          /*{
            id: 'map-with-search-box',
            title: 'MapWithSearchBox',
            messageId: 'sidebar.googleMap.mapWithSearchBox',
            url: '/google-map/map-with-search-box',
          },*/
          {
            id: 'popup-info',
            title: 'PopUpInfo',
            messageId: 'sidebar.googleMap.popUpInfo',
            type: 'item',
            url: '/third-party/google-map/popup-info',
          },
          {
            id: 'street-view',
            title: 'StreetView',
            messageId: 'sidebar.googleMap.streetView',
            type: 'item',
            url: '/third-party/google-map/street-view',
          },
          {
            id: 'styled',
            title: 'Styled',
            messageId: 'sidebar.googleMap.styled',
            type: 'item',
            url: '/third-party/google-map/styled',
          },
          {
            id: 'traffic-layer',
            title: 'TrafficLayer',
            messageId: 'sidebar.googleMap.trafficLayer',
            type: 'item',
            url: '/third-party/google-map/traffic-layer',
          },
        ],
      },
      {
        id: 'recharts',
        title: 'Recharts',
        messageId: 'sidebar.recharts',
        icon: <RiBarChart2Line />,
        url: 'recharts',
        type: 'collapse',
        children: [
          {
            id: 'area',
            title: 'Area Chart',
            messageId: 'sidebar.recharts.areaChart',
            type: 'item',
            url: '/third-party/recharts/area',
          },
          {
            id: 'bar',
            title: 'Bar Chart',
            messageId: 'sidebar.recharts.barChart',
            type: 'item',
            url: '/third-party/recharts/bar',
          },
          {
            id: 'composed',
            title: 'Composed Chart',
            messageId: 'sidebar.recharts.composedChart',
            type: 'item',
            url: '/third-party/recharts/composed',
          },
          {
            id: 'line',
            title: 'Line Chart',
            messageId: 'sidebar.recharts.lineChart',
            type: 'item',
            url: '/third-party/recharts/line',
          },
          {
            id: 'pie',
            title: 'Pie Chart',
            messageId: 'sidebar.recharts.pieChart',
            type: 'item',
            url: '/third-party/recharts/pie',
          },
          {
            id: 'radar',
            title: 'Radar Chart',
            messageId: 'sidebar.recharts.radarChart',
            type: 'item',
            url: '/third-party/recharts/radar',
          },
          {
            id: 'radial',
            title: 'Radial Chart',
            messageId: 'sidebar.recharts.radialChart',
            type: 'item',
            url: '/third-party/recharts/radial',
          },
          {
            id: 'scatter',
            title: 'Scatter Chart',
            messageId: 'sidebar.recharts.scatterChart',
            type: 'item',
            url: '/third-party/recharts/scatter',
          },
          {
            id: 'funnel',
            title: 'Funnel Chart',
            messageId: 'sidebar.recharts.funnelChart',
            type: 'item',
            url: '/third-party/recharts/funnel',
          },
          {
            id: 'treemap',
            title: 'Treemap Chart',
            messageId: 'sidebar.recharts.treeChart',
            type: 'item',
            url: '/third-party/recharts/treemap',
          },
        ],
      },
      {
        id: 'big-calendar',
        title: 'Big Calendar',
        messageId: 'sidebar.bigCalender',
        icon: <FaRegCalendarAlt />,
        url: 'big-calendar',
        type: 'collapse',
        children: [
          {
            id: 'basic',
            title: 'Basic',
            messageId: 'sidebar.bigCalender.basic',
            type: 'item',
            url: '/third-party/big-calendar/basic',
          },
          {
            id: 'cultures',
            title: 'Cultures',
            messageId: 'sidebar.bigCalender.cultures',
            type: 'item',
            url: '/third-party/big-calendar/cultures',
          },
          {
            id: 'dnd',
            title: 'Dnd',
            messageId: 'sidebar.bigCalender.dnd',
            type: 'item',
            url: '/third-party/big-calendar/dnd',
          },
          {
            id: 'popup',
            title: 'Popup',
            messageId: 'sidebar.bigCalender.popup',
            type: 'item',
            url: '/third-party/big-calendar/popup',
          },
          {
            id: 'rendering',
            title: 'Rendering',
            messageId: 'sidebar.bigCalender.rendering',
            type: 'item',
            url: '/third-party/big-calendar/rendering',
          },
          {
            id: 'selectable',
            title: 'Selectable',
            messageId: 'sidebar.bigCalender.selectable',
            type: 'item',
            url: '/third-party/big-calendar/selectable',
          },
          {
            id: 'timeslots',
            title: 'Timeslots',
            messageId: 'sidebar.bigCalender.timeSlots',
            type: 'item',
            url: '/third-party/big-calendar/timeslots',
          },
        ],
      },
      {
        id: 'react-color',
        title: 'React Color',
        messageId: 'sidebar.reactColor',
        icon: <MdInvertColors />,
        type: 'item',
        url: '/third-party/react-color',
      },
      {
        id: 'react-dropzone',
        title: 'React Dropzone',
        messageId: 'sidebar.reactDropzone',
        icon: <CgAttachment />,
        type: 'item',
        url: '/third-party/react-dropzone',
      },
      {
        id: 'react-player',
        title: 'Player',
        messageId: 'sidebar.player',
        icon: <DiHtml5Multimedia />,
        type: 'item',
        url: '/third-party/react-player',
      },
      // {
      //   id: 'time-line',
      //   title: 'Time Line',
      //   messageId: 'sidebar.pages.timeLine',
      //   icon: <MdTimeline />,
      //   type: 'item',
      //   url: '/third-party/time-line',
      // },
    ],
  },
  {
    id: 'extra-pages',
    title: 'Extra Pages',
    messageId: 'sidebar.pages.extraPages',
    type: 'group',
    children: [
      {
        id: 'account',
        title: 'Account',
        messageId: 'sidebar.pages.extraPages.account',
        type: 'item',
        icon: <MdOutlineManageAccounts />,
        url: '/extra-pages/user-profile',
      },
      {
        id: 'about-us',
        title: 'About Us',
        messageId: 'sidebar.pages.extraPages.aboutUs',
        type: 'item',
        icon: <FiUsers />,
        url: '/extra-pages/about-us',
      },
      {
        id: 'contact-us',
        title: 'Contact Us',
        messageId: 'sidebar.pages.extraPages.contactUs',
        type: 'item',
        icon: <MdOutlineContactSupport />,
        url: '/extra-pages/contact-us',
      },
      {
        id: 'knowledge-base',
        title: 'Contact Us',
        messageId: 'sidebar.pages.extraPages.knowledgeBase',
        type: 'item',
        icon: <BiBookReader />,
        url: '/extra-pages/knowledge-base',
      },
      {
        id: 'portfolio',
        title: 'Portfolio',
        messageId: 'sidebar.pages.extraPages.portfolio',
        type: 'item',
        icon: <BsBriefcase />,
        url: '/extra-pages/portfolio',
      },
      {
        id: 'faq',
        title: 'FAQ',
        messageId: 'sidebar.pages.extraPages.faq',
        type: 'item',
        icon: <BsQuestionDiamond />,
        url: '/extra-pages/faq',
      },
      {
        id: 'pricing',
        title: 'Pricing',
        messageId: 'sidebar.pages.extraPages.pricing',
        type: 'item',
        icon: <BiDollar />,
        url: '/extra-pages/pricing',
      },
      {
        id: 'user',
        title: 'User Pages',
        messageId: 'sidebar.pages.userPages',
        type: 'collapse',
        icon: <RiShieldUserLine />,
        children: [
          {
            id: 'sign-in-1',
            title: 'SignIn-1',
            messageId: 'sidebar.pages.userPages.signIn1',
            type: 'item',
            url: '/extra-pages/user/sign-in-1',
          },
          {
            id: 'sign-in-2',
            title: 'SignIn-2',
            messageId: 'sidebar.pages.userPages.signIn2',
            type: 'item',
            url: '/extra-pages/user/sign-in-2',
          },
          {
            id: 'sign-up-1',
            title: 'SignUp-1',
            messageId: 'sidebar.pages.userPages.signUp1',
            type: 'item',
            url: '/extra-pages/user/sign-up-1',
          },
          {
            id: 'sign-up-2',
            title: 'SignUp-2',
            messageId: 'sidebar.pages.userPages.signUp2',
            type: 'item',
            url: '/extra-pages/user/sign-up-2',
          },
          {
            id: 'forgot-password-1',
            title: 'Forgot Password-1',
            messageId: 'sidebar.pages.userPages.forgetPassword1',
            type: 'item',
            url: '/extra-pages/user/forgot-password-1',
          },
          {
            id: 'forgot-password-2',
            title: 'Forgot Password-2',
            messageId: 'sidebar.pages.userPages.forgetPassword2',
            type: 'item',
            url: '/extra-pages/user/forgot-password-2',
          },
          {
            id: 'reset-password-1',
            title: 'Reset Password-1',
            messageId: 'sidebar.pages.userPages.resetPassword1',
            type: 'item',
            url: '/extra-pages/user/reset-password-1',
          },
          {
            id: 'reset-password-2',
            title: 'Reset Password-2',
            messageId: 'sidebar.pages.userPages.resetPassword2',
            type: 'item',
            url: '/extra-pages/user/reset-password-2',
          },
          {
            id: 'lock-1',
            title: 'Lock Screen-1',
            messageId: 'sidebar.pages.userPages.lockScreen1',
            type: 'item',
            url: '/extra-pages/user/lock-1',
          },
          {
            id: 'lock-2',
            title: 'Lock Screen-2',
            messageId: 'sidebar.pages.userPages.lockScreen2',
            type: 'item',
            url: '/extra-pages/user/lock-2',
          },
        ],
      },
      {
        id: 'list-type',
        title: 'User List',
        messageId: 'sidebar.pages.userList',
        type: 'collapse',
        icon: <AiOutlineUnorderedList />,
        children: [
          {
            id: 'morden',
            title: 'Modern',
            messageId: 'sidebar.pages.userList.modern',
            type: 'item',
            url: '/extra-pages/list-type/morden',
          },
          {
            id: 'standard',
            title: 'Standard',
            messageId: 'sidebar.pages.userList.standard',
            type: 'item',
            url: '/extra-pages/list-type/standard',
          },
          {
            id: 'flat',
            title: 'Flat',
            messageId: 'sidebar.pages.userList.flat',
            type: 'item',
            url: '/extra-pages/list-type/flat',
          },
        ],
      },
      {
        id: 'error-pages',
        title: 'Error Pages',
        messageId: 'sidebar.pages.errorPages',
        type: 'collapse',
        icon: <BiErrorAlt />,
        children: [
          {
            id: 'error-401',
            title: '402',
            messageId: 'sidebar.pages.errorPages.401',
            type: 'item',
            url: '/extra-pages/error-pages/error-401',
          },
          {
            id: 'error-403',
            title: '403',
            messageId: 'sidebar.pages.errorPages.403',
            type: 'item',
            url: '/extra-pages/error-pages/error-403',
          },
          {
            id: 'error-404',
            title: '404',
            messageId: 'sidebar.pages.errorPages.404',
            type: 'item',
            url: '/extra-pages/error-pages/error-404',
          },
          {
            id: 'error-500',
            title: '500',
            messageId: 'sidebar.pages.errorPages.500',
            type: 'item',
            url: '/extra-pages/error-pages/error-500',
          },
          {
            id: 'error-503',
            title: '503',
            messageId: 'sidebar.pages.errorPages.503',
            type: 'item',
            url: '/extra-pages/error-pages/error-503',
          },
          {
            id: 'maintenance',
            title: 'Maintenance',
            messageId: 'sidebar.pages.errorPages.maintenance',
            type: 'item',
            url: '/extra-pages/error-pages/maintenance',
          },
          {
            id: 'coming-soon',
            title: 'Coming Soon',
            messageId: 'sidebar.pages.errorPages.comingSoon',
            type: 'item',
            url: '/extra-pages/error-pages/coming-soon',
          },
        ],
      },
    ],
  },
];
export default routesConfig;
